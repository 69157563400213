import React, { useEffect } from 'react'
import cn from 'classnames'
import anim from './anim'
import * as s from './Pic.module.scss'

export const Pic = () => {
  useEffect(anim, [])

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 660 550">
      <line
        y1="-0.5"
        x2="550"
        y2="-0.5"
        transform="matrix(2.18557e-08 1 1 -8.74228e-08 110 0)"
        stroke="url(#a)"
        strokeDasharray="11 11"
      />
      <line
        y1="-0.5"
        x2="550"
        y2="-0.5"
        transform="matrix(2.18557e-08 1 1 -8.74228e-08 220 0)"
        stroke="url(#a)"
        strokeDasharray="11 11"
      />
      <line
        y1="-0.5"
        x2="550"
        y2="-0.5"
        transform="matrix(2.18557e-08 1 1 -8.74228e-08 440 0)"
        stroke="url(#a)"
        strokeDasharray="11 11"
      />
      <line
        y1="-0.5"
        x2="550"
        y2="-0.5"
        transform="matrix(2.18557e-08 1 1 -8.74228e-08 550 0)"
        stroke="url(#a)"
        strokeDasharray="11 11"
      />
      <line
        y1="-0.5"
        x2="660"
        y2="-0.5"
        transform="matrix(1 0 0 -1 0 220)"
        stroke="black"
        strokeOpacity="0.1"
        strokeDasharray="11 11"
      />
      <line
        y1="-0.5"
        x2="660"
        y2="-0.5"
        transform="matrix(1 0 0 -1 0 110)"
        stroke="black"
        strokeOpacity="0.1"
        strokeDasharray="11 11"
      />
      <line
        y1="-0.5"
        x2="660"
        y2="-0.5"
        transform="matrix(1 0 0 -1 0 330)"
        stroke="black"
        strokeOpacity="0.1"
        strokeDasharray="11 11"
      />
      <line
        y1="-0.5"
        x2="660"
        y2="-0.5"
        transform="matrix(1 0 0 -1 0 440)"
        stroke="black"
        strokeOpacity="0.1"
        strokeDasharray="11 11"
      />
      <rect width="101" height="101" fill="#FFEDD9" rx="8" transform="matrix(-1 0 0 1 106 115)" />
      <path
        className={cn(s.pic_icon, 'about_icon')}
        fill="#E1BC97"
        d="m46 172-1.5 1.2v.1L46 172Zm18.1 0 1.4 1.3-1.4-1.4Zm-9 7.2v2h2v-2h-2Zm2-13.8a2 2 0 0 0-4 0h4ZM53 148a2 2 0 1 0 4 0h-4Zm4-6a2 2 0 1 0-4 0h4Zm3.1 6.6a2 2 0 0 0 3.6 1.8l-3.6-1.8Zm6.5-3.7a2 2 0 0 0-3.5-1.9l3.5 2Zm-.5 6.8a2 2 0 0 0 2.8 2.9l-2.8-2.9Zm7.2-1.6a2 2 0 1 0-2.8-2.8l2.8 2.8Zm-27.2.3a2 2 0 1 0 3.6-1.8l-3.6 1.8Zm.6-7.4a2 2 0 1 0-3.5 2l3.5-2ZM41 155a2 2 0 1 0 2.9-2.8l-3 2.8Zm-1.5-7.5a2 2 0 0 0-3 2.7l3-2.7Zm5.6 17c0-4.8 4.8-9 10-9v-4c-7 0-14 5.6-14 13h4Zm10-9c5 0 9.9 4.2 9.9 9h4c0-7.4-7-13-14-13v4Zm-7.6 15.2a9.1 9.1 0 0 1-2.4-6.2h-4a13 13 0 0 0 3.4 8.8l3-2.6Zm15.2-.1a10.3 10.3 0 0 0-2.7 4 14 14 0 0 0-.8 4.7l4 .1c.1-1.6.2-2.6.5-3.5a8 8 0 0 1 1.8-2.5l-2.8-2.8Zm-1.5 6.7H59v4h2.2v-4Zm-2.2 0h-2.4v4H59v-4Zm-2.4 0H54v4h2.7v-4Zm9-4c2-2.3 3.4-5.4 3.4-8.8h-4c0 2.4-1 4.5-2.4 6.2l3 2.6ZM51 189h8v-4h-8v4Zm-6.4-15.7a6 6 0 0 1 1.7 2.5c.3.9.5 1.9.5 3.5l4-.1a14 14 0 0 0-.7-4.7 9.5 9.5 0 0 0-2.7-4l-2.8 2.8Zm4.2 8H55v-4h-6.2v4Zm6.2-4h-1v4h1v-4Zm-2-11.9v13.8h4v-13.8h-4Zm10.2 16.3v-2.5h-4v2.5h4Zm-16.4-2.5v2.5h4v-2.5h-4ZM57 148v-6h-4v6h4Zm2 41c2.4 0 4.2-2 4.2-4.2h-4v.1l-.2.1v4Zm-8-4-.2-.2h-4c0 2.3 1.9 4.2 4.2 4.2v-4Zm12.8-34.6 3-5.5-3.6-1.9-3 5.6 3.6 1.8Zm5.2 4.2 4.4-4.5-2.8-2.8-4.4 4.4 2.8 2.9Zm-19.2-6-3-5.6-3.5 2 3 5.4 3.5-1.8Zm-6 3.5-4.3-4.7-3 2.7 4.5 4.8 2.9-2.8Zm3 29.6v1.2h4v-1.2h-4Zm0 1.2v1.9h4v-2h-4Zm16.4 1.9v-2h-4v2h4Zm0-2v-1.1h-4v1.2h4Z"
      />
      <rect width="101" height="101" x="225" y="115" fill="#C9F1D0" rx="8" />
      <path
        className={cn(s.pic_icon, 'about_icon')}
        fill="#96D09F"
        d="M290 162h2c0-.5-.2-1-.6-1.4L290 162Zm-14-14 1.4-1.4a2 2 0 0 0-1.4-.6v2Zm10 34h-20v4h20v-4Zm-22-2v-28h-4v28h4Zm24-18v18h4v-18h-4Zm-22-12h10v-4h-10v4Zm25.4 10.6-14-14-2.8 2.8 14 14 2.8-2.8ZM274 148v13h4v-13h-4Zm3 16h13v-4h-13v4Zm-11 18a2 2 0 0 1-2-2h-4a6 6 0 0 0 6 6v-4Zm20 4a6 6 0 0 0 6-6h-4a2 2 0 0 1-2 2v4Zm-12-25a3 3 0 0 0 3 3v-4c.6 0 1 .4 1 1h-4Zm-10-9c0-1.1.9-2 2-2v-4a6 6 0 0 0-6 6h4Z"
      />
      <rect width="101" height="101" x="444" y="115" fill="#FFE8F7" rx="8" />
      <g fill="#EABBD9" className={cn(s.pic_icon, 'about_icon')}>
        <path d="M511 187.1h-32a6 6 0 0 1-6-6v-32a6 6 0 0 1 6-6h32a6 6 0 0 1 6 6v32a6 6 0 0 1-6 6Zm-32-39.6c-.9 0-1.6.7-1.6 1.6v32c0 .9.7 1.6 1.6 1.6h32c1 0 1.7-.7 1.7-1.6v-32c-.1-.9-.8-1.6-1.7-1.6h-32Z" />
        <path d="M513.8 159h-37.6c-1.2 0-2.2-.9-2.2-2.2 0-1.2 1-2.2 2.2-2.2h37.6c1.2 0 2.2 1 2.2 2.3 0 1.2-1 2.2-2.2 2.2Z" />
        <path d="M488 186c-1.3 0-2.2-1-2.2-2.2v-26.5c0-1.3 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2v26.5c0 1.3-1 2.2-2.2 2.2Z" />
      </g>
      <rect width="101" height="101" x="444" y="334" fill="#C0EEE5" rx="8" />
      <path
        className={cn(s.pic_icon, 'about_icon')}
        fill="#83D4C5"
        d="M488.6 399c-.6 0-1-.2-1.5-.6l-13.4-12.9a2 2 0 0 1 0-3l13.4-12.9c.9-.8 2.3-.8 3 0a2 2 0 0 1 0 3L478.4 384l11.9 11.3a2 2 0 0 1 0 3 2 2 0 0 1-1.6.7Zm12.8 0c-.6 0-1.1-.2-1.6-.6a2 2 0 0 1 0-3l12-11.4-12-11.4a2 2 0 0 1 0-3c.8-.8 2.2-.8 3 0l13.5 13a2 2 0 0 1 0 3l-13.4 12.8c-.4.4-1 .6-1.5.6Z"
      />
      <rect width="101" height="101" x="326" y="334" fill="#FFCACA" rx="8" transform="rotate(90 326 334)" />
      <g className={cn(s.pic_icon, 'about_icon')}>
        <path
          fill="#E0A6A6"
          d="M290 381h2c0-.5-.2-1-.6-1.4L290 381Zm-14-14 1.4-1.4a2 2 0 0 0-1.4-.6v2Zm10 34h-20v4h20v-4Zm-22-2v-28h-4v28h4Zm24-18v18h4v-18h-4Zm-22-12h10v-4h-10v4Zm25.4 10.6-14-14-2.8 2.8 14 14 2.8-2.8ZM274 367v13h4v-13h-4Zm3 16h13v-4h-13v4Zm-11 18a2 2 0 0 1-2-2h-4a6 6 0 0 0 6 6v-4Zm20 4a6 6 0 0 0 6-6h-4a2 2 0 0 1-2 2v4Zm-12-25a3 3 0 0 0 3 3v-4c.6 0 1 .4 1 1h-4Zm-10-9c0-1.1.9-2 2-2v-4a6 6 0 0 0-6 6h4Z"
        />
        <path strokeWidth="4" strokeLinecap="round" stroke="#E0A6A6" d="m271 390 3.3 3.3c.4.4 1 .4 1.4 0l6.3-6.3" />
      </g>
      <rect width="101" height="101" x="5" y="334" fill="#D4DDFB" rx="8" />
      <g className={cn(s.pic_icon, 'about_icon')}>
        <path
          stroke="#A5B5E9"
          strokeWidth="4"
          strokeLinecap="round"
          d="M43 403v-37m0 0h28.8c.4 0 .7.5.3.9l-6.7 6.7c-.2.2-.2.6 0 .8L72 381c.4.4.1.9-.3.9H43"
        />
        <ellipse strokeWidth="4" cx="43" cy="402" stroke="#A5B5E9" rx="5" ry="3" />
      </g>

      <g className={cn(s.pic_arrow, 'about_arrow about_arrow_left')}>
        <path fill="#000" d="M146 162a2 2 0 1 0 0 4v-4Zm0 4h40v-4h-40v4Z" />
        <path stroke="#000" d="m172 150 14 14-14 14" />
      </g>
      <g className={cn(s.pic_arrow, 'about_arrow about_arrow_left')}>
        <path fill="#000" d="M365 163a2 2 0 1 0 0 4v-4Zm0 4h40v-4h-40v4Z" />
        <path stroke="#000" d="m391 151 14 14-14 14" />
      </g>
      <g className={cn(s.pic_arrow, 'about_arrow about_arrow_down')}>
        <path fill="#000" d="M497 255a2 2 0 1 0-4 0h4Zm-4 0v40h4v-40h-4Z" />
        <path stroke="#000" d="m509 281-14 14-14-14" />
      </g>
      <g className={cn(s.pic_arrow, 'about_arrow about_arrow_right')}>
        <path fill="#000" d="M405 387a2 2 0 1 0 0-4v4Zm0-4h-40v4h40v-4Z" />
        <path stroke="#000" d="m379 399-14-14 14-14" />
      </g>
      <g className={cn(s.pic_arrow, 'about_arrow about_arrow_right')}>
        <path fill="#000" d="M186 386a2 2 0 1 0 0-4v4Zm0-4h-40v4h40v-4Z" />
        <path stroke="#000" d="m160 398-14-14 14-14" />
      </g>

      <defs>
        <linearGradient id="a" x1="0" x2="550" y1="0" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0" />
          <stop offset=".2" stopOpacity="0.1" />
          <stop offset=".8" stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
