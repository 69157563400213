import React from 'react'
import gsap from 'gsap'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import Icon from '~components/Icon'
import { Pic } from './Pic'
import { hero, hero_content, hero_title, hero_descr, hero_btns, hero_pic } from './style.module.scss'

export const Hero = ({ title, descr, buttons }) => {
  const handleScroll = (e) => {
    e.preventDefault()
    gsap.to(window, { scrollTo: '#about', ease: 'power2' })
  }
  return (
    <Container as="section" className={hero}>
      <div className={hero_content}>
        <h1 className={hero_title} dangerouslySetInnerHTML={{ __html: title }} />
        <p className={hero_descr}>{descr}</p>
        <div className={hero_btns}>
          {buttons.map(({ mode, text, href, icon }) =>
            mode !== 'link' ? (
              <Button key={text} variant={mode} as={Link} to={href}>
                {text}
              </Button>
            ) : (
              <Button key={text} variant={mode} as={Link} to={href} onClick={(e) => handleScroll(e)}>
                {text}
                {icon && <Icon name={icon} size="24" />}
              </Button>
            )
          )}
        </div>
      </div>
      <div className={hero_pic}>
        <Pic />
      </div>
    </Container>
  )
}
