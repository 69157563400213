// extracted by mini-css-extract-plugin
export var app = "_6q_bvh";
export var blockchain = "_6q_bxh";
export var design = "_6q_bth";
export var mob = "_6q_bwh";
export var services = "_6q_bnh";
export var services_descr = "_6q_bzh";
export var services_heading = "_6q_bph";
export var services_icon = "_6q_bsh";
export var services_item = "_6q_brh";
export var services_list = "_6q_bqh";
export var services_title = "_6q_byh";