import gsap from 'gsap'
import CustomEase from 'gsap/CustomEase'

const anim = () => {
  const rectsDefaults = { ease: 'elastic.out(1, 1)', duration: 1 }
  const iconsDefaults = { transformOrigin: '50%', scale: 0 }
  const rects = document.querySelectorAll('.hero_rect')
  const icons = document.querySelectorAll('.hero_icon')

  gsap.set('.hero_path', { transformOrigin: '50%' })
  gsap.set('.hero_rect-big', { transformOrigin: '50%' })

  gsap
    .timeline({ defaults: { duration: 0.5, ease: CustomEase.create('custom', 'M0,0 C0.25,0.1 0.25,1 1,1 ') } })
    .to('.hero_rect-big', { scale: 1 }, 0)
    .to('.hero_rect-blue', { yPercent: '-=25', xPercent: '-=25', ease: 'power1.in' }, 0)
    .to('.hero_rect-red', { yPercent: '+=25', xPercent: '+=25', ease: 'power1.in' }, 0)
    .from('.hero_path-red', { yPercent: 26, xPercent: 26, ease: 'power1.in' }, 0)
    .from('.hero_path-blue', { yPercent: -25, xPercent: -25, ease: 'power1.in' }, 0)
    .fromTo('.hero_path', { scale: 0 }, { scale: 1, autoAlpha: 1 }, 0)
    // 1
    .from(rects[0], { ...rectsDefaults, xPercent: -200 }, 0.3)
    .to(rects[0], { autoAlpha: 1 }, 0.3)
    .from(icons[0], { ...iconsDefaults }, 0.3)
    // 2
    .from(rects[1], { ...rectsDefaults, xPercent: 200 }, 0.6)
    .to(rects[1], { autoAlpha: 1 }, 0.6)
    .from(icons[1], { ...iconsDefaults }, 0.6)
    // 3
    .from(rects[2], { ...rectsDefaults, yPercent: 200 }, 0.9)
    .to(rects[2], { autoAlpha: 1 }, 0.9)
    .from(icons[2], { ...iconsDefaults }, 0.9)
    // 4
    .from(rects[3], { ...rectsDefaults, xPercent: 200 }, 1.2)
    .to(rects[3], { autoAlpha: 1 }, 1.2)
    .from(icons[3], { ...iconsDefaults }, 1.2)
    // 5
    .from(rects[4], { ...rectsDefaults, xPercent: 200 }, 1.3)
    .to(rects[4], { autoAlpha: 1 }, 1.3)
    .from(icons[4], { ...iconsDefaults }, 1.3)
    // 6
    .from(rects[5], { ...rectsDefaults, xPercent: -200 }, 1.4)
    .to(rects[5], { autoAlpha: 1 }, 1.4)
    .from(icons[5], { ...iconsDefaults }, 1.4)
}

export default anim
