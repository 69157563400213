import React from 'react'
import { Container } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Pic } from './Pic'
import { about, about_inner, about_pic, about_content, about_title, about_descr, about_list } from './style.module.scss'

export const About = ({ title, descr, list }) => {
  return (
    <Container id="about" as="section" className={about}>
      <div className={about_inner}>
        <div className={about_pic}>
          <Pic />
        </div>
        <div className={about_content}>
          <h2 className={about_title}>{title}</h2>
          <div className={about_descr}>
            {descr.map((item, i) => (
              <p key={'p' + i}>{item}</p>
            ))}
          </div>
          <ul className={about_list}>
            {list.map((item) => (
              <li key={item.name}>
                <GatsbyImage
                  image={item.childImageSharp.gatsbyImageData}
                  alt={item.name}
                  style={{ display: 'block' }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  )
}
