import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import * as s from './style.module.scss'

export const Cases = () => {
  const data = useStaticQuery(graphql`
    {
      casesJson {
        title
        descr
        mobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 290, quality: 80, placeholder: TRACED_SVG)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1025, quality: 80, placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)
  const { title, descr, mobile, tablet } = data.casesJson
  return (
    <Container as="section">
      <div className={s.cases}>
        <div className={s.cases_content}>
          <h2>{title}</h2>
          <div className={s.cases_descr}>
            {descr.map((item, i) => (
              <p key={'p' + i}>{item}</p>
            ))}
          </div>
        </div>
        {mobile.map((item, i) => {
          const count = i + 1
          return (
            <GatsbyImage
              key={`s${i}`}
              className={cn(s.cases_screen, s.phone, [s['pos_' + count]])}
              image={item.childImageSharp.gatsbyImageData}
              alt={'Screenshot ' + count}
              style={{ display: 'block' }}
            />
          )
        })}
        <GatsbyImage
          className={cn(s.cases_screen, s.tablet)}
          image={tablet.childImageSharp.gatsbyImageData}
          alt="Tablet screenshot"
          style={{ display: 'block' }}
        />
      </div>
    </Container>
  )
}
