import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

const anim = () => {
  ScrollTrigger.saveStyles('.about_arrow_left, .about_arrow_down, .about_arrow_right, .about_icon')

  ScrollTrigger.matchMedia({
    '(min-width:1024px)': () => {
      gsap.set('.about_arrow', { autoAlpha: 0.2 })
      gsap.set('.about_icon', { autoAlpha: 1 })
      gsap
        .timeline({
          defaults: { ease: 'elastic.out(1, 0.75)', duration: 0.9, scale: 0, stagger: 0.3 },
          scrollTrigger: {
            trigger: '#about',
            markers: process.env.GATSBY_VERCEL_ENV === 'preview',
            id: 'aboutSt',
            start: 'top center',
          },
        })
        .from('.about_arrow_left', { x: '-=30', transformOrigin: '0% 50%' }, 0.1)
        .from('.about_arrow_down', { y: '-=30', transformOrigin: '50% 0%' }, 0.7)
        .from('.about_arrow_right', { x: '+=30', transformOrigin: '100% 50%' }, 1)
        .from('.about_icon', { transformOrigin: '50% 50%' }, 0)
    },
  })
}
export default anim
