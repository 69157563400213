import React from 'react'
import { Container } from 'react-bootstrap'
import Icon from '~components/Icon'
import cn from 'classnames'
import * as s from './style.module.scss'

export const Services = ({ title, list }) => {
  return (
    <Container as="section" className={s.services}>
      <h2 className={s.services_heading}>{title}</h2>
      <ul className={s.services_list}>
        {list.map(({ title, descr, icon }) => (
          <li className={s.services_item} key={icon}>
            <div className={cn(s.services_icon, s[icon])}>
              <Icon name={icon} size={144} />
            </div>
            <p className={s.services_title}>{title}</p>
            <p className={s.services_descr}>{descr}</p>
          </li>
        ))}
      </ul>
    </Container>
  )
}
