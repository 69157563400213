import React, { useEffect } from 'react'
import anim from './anim'
import cn from 'classnames'
import * as s from './Pic.module.scss'

export const Pic = () => {
  useEffect(anim, [])

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 770 660" className={s.pic}>
      <g>
        <path className={s.pic_line} stroke="url(#a)" d="M660.5 0v660" />
        <path className={s.pic_line} stroke="url(#b)" d="M550.5 0v660" />
        <path className={s.pic_line} stroke="url(#c)" d="M330.5 0v660" />
        <path className={s.pic_line} stroke="url(#d)" d="M770 220.5H0" />
        <path className={s.pic_line} stroke="url(#e)" d="M770 110.5H0" />
        <path className={s.pic_line} stroke="url(#f)" d="M770 330.5H0" />
        <path className={s.pic_line} stroke="url(#g)" d="M770 440.5H0" />
        <path className={s.pic_line} stroke="url(#h)" d="M770 550.5H0" />
        <path className={s.pic_line} stroke="url(#i)" d="M220.5 0v660" />
      </g>
      <g className={cn(s.pic_rect, 'hero_rect')}>
        <rect width="101" height="101" x="115" y="115" fill="#FFEDD9" rx="8" />
        <g fill="#E1BC97" className="hero_icon">
          <path d="M154.2 161.2a8.2 8.2 0 0 1-8.2-8c0-4.5 3.7-8.2 8.2-8.2 4.5 0 8.2 3.7 8.2 8.2a8 8 0 0 1-8.2 8Zm0-11.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 1 0 0-7Zm23.7 35.1a8.1 8.1 0 1 1 0-16.2c4.5 0 8.2 3.6 8.2 8 0 4.6-3.8 8.2-8.2 8.2Zm0-11.6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 1 0 0-7Z" />
          <path d="M154.2 185c-1.3 0-2.3-1-2.3-2.3V159c0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3v23.6c0 1.2-1 2.3-2.3 2.3Z" />
          <path d="M172 179c-15.5 0-20-13.1-20.1-20 0-1.3 1-2.3 2.2-2.4 1.4 0 2.4 1 2.4 2.2 0 .6.7 15.5 15.6 15.5 1.3 0 2.3 1 2.3 2.3 0 1.3-1 2.4-2.4 2.4Z" />
        </g>
      </g>
      <g className={cn(s.pic_rect, 'hero_rect')}>
        <rect width="101" height="101" x="555" y="115" fill="#DAF3FA" rx="8" />
        <path
          className="hero_icon"
          fill="#A2CCD9"
          d="M606 187a2 2 0 0 1-1.1-.3c-22-9.1-20.9-34.5-20.9-34.8 0-.8.7-1.7 1.5-2l19.8-6.8h1.4l19.8 6.9c1 .2 1.5 1 1.5 2 0 .2 1.1 25.7-21 34.8l-.6.2h-.4Zm-17.6-33.4c0 5 1.7 21.8 17.6 28.9 16-7 17.5-23.8 17.6-28.9l-17.6-6-17.6 6Z"
        />
      </g>
      <g className={cn(s.pic_rect, 'hero_rect')}>
        <rect width="101" height="101" x="665" y="335" fill="#D4EEEA" rx="8" />
        <g fill="#9ACCC4" className="hero_icon">
          <path d="M716 407a22 22 0 1 1 0-44 22 22 0 0 1 0 44Zm0-39.6a17.6 17.6 0 1 0 0 35.2 17.6 17.6 0 0 0 0-35.2Z" />
          <path d="M716.7 404.7c-.3 0-.6 0-1-.2a22 22 0 0 1 0-39c1.1-.6 2.4-.1 3 1 .5 1 .1 2.4-1 2.9a17.7 17.7 0 0 0 0 31.2c1.2.6 1.5 2 1 3-.4.8-1.2 1.1-2 1.1Z" />
          <path d="M715.3 404.7c-.8 0-1.6-.4-2-1.2a2.1 2.1 0 0 1 1-2.9 17.5 17.5 0 0 0 0-31.2c-1.2-.6-1.5-2-1-3 .6-1 1.9-1.4 3-.9a22 22 0 0 1 0 39l-1 .2Z" />
          <path d="M735.8 387.2h-39.6c-1.2 0-2.2-1-2.2-2.2 0-1.3 1-2.2 2.2-2.2h39.6c1.2 0 2.2 1 2.2 2.2 0 1.3-1 2.2-2.2 2.2Z" />
        </g>
      </g>
      <g className={cn(s.pic_rect, 'hero_rect')} filter="url(#o)">
        <rect width="101" height="101" x="335" y="445" fill="url(#p)" rx="8" />
        <g fill="#fff" filter="url(#q)" className="hero_icon">
          <path d="M392.6 503.8h-13.2c-1.2 0-2.2-1-2.2-2.2v-13.1c0-1.3 1-2.2 2.2-2.2h13.2c1.2 0 2.2 1 2.2 2.2v13.1c0 1.2-1 2.2-2.2 2.2Zm-11-4.4h8.8v-8.7h-8.8v8.7Z" />
          <path d="M402.6 513.8h-33.2c-1.2 0-2.2-1-2.2-2.2v-33.1c0-1.2 1-2.2 2.2-2.2h33.2c1.2 0 2.2 1 2.2 2.2v33c0 1.2-1 2.3-2.2 2.3Zm-31-4.4h28.8v-28.7h-28.8v28.7Z" />
          <path d="M379.5 517c-1.2 0-2.1-1-2.1-2.1v-3.2a2.1 2.1 0 0 1 4.2 0v3.2c0 1.1-1 2.1-2.1 2.1Zm13.1 0c-1.2 0-2.2-1-2.2-2.1v-3.2a2.1 2.1 0 0 1 4.3 0v3.2c0 1.1-1 2.1-2.1 2.1Zm-13.1-36.7a2 2 0 0 1-2.1-2V475a2.1 2.1 0 0 1 4.2 0v3.2c0 1-1 2-2.1 2Zm13.1 0c-1.2 0-2.2-1-2.2-2V475a2.1 2.1 0 0 1 4.3 0v3.2c0 1-1 2-2.1 2Zm13.3 10.4h-3.2a2.1 2.1 0 1 1 0-4.3h3.2a2.1 2.1 0 1 1 0 4.3Zm0 12.8h-3.2a2.1 2.1 0 1 1 0-4.2h3.2a2.1 2.1 0 1 1 0 4.2Zm-36.7-12.8h-3a2.1 2.1 0 1 1 0-4.3h3c1.3 0 2.2 1 2.2 2.1 0 1.2-1 2.2-2.2 2.2Zm0 12.8h-3a2.1 2.1 0 1 1 0-4.2h3c1.3 0 2.2 1 2.2 2 0 1.3-1 2.2-2.2 2.2Z" />
        </g>
      </g>
      <g className={cn(s.pic_rect, 'hero_rect')}>
        <rect width="101" height="101" x="445" y="445" fill="#FFE8F7" rx="8" />
        <g fill="#EABBD9" className="hero_icon">
          <path d="M512 517.1h-32a6 6 0 0 1-6-6v-32a6 6 0 0 1 6-6h32a6 6 0 0 1 6 6v32a6 6 0 0 1-6 6Zm-32-39.6c-.9 0-1.6.7-1.6 1.6v32c0 .9.7 1.6 1.6 1.6h32c1 0 1.7-.7 1.7-1.6v-32c-.1-.9-.8-1.6-1.7-1.6h-32Z" />
          <path d="M514.8 489h-37.6c-1.2 0-2.2-.9-2.2-2.1 0-1.3 1-2.3 2.2-2.3h37.6c1.2 0 2.2 1 2.2 2.3 0 1.2-1 2.2-2.2 2.2Z" />
          <path d="M489 516c-1.3 0-2.2-1-2.2-2.2v-26.5c0-1.3 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2v26.5c0 1.3-1 2.2-2.2 2.2Z" />
        </g>
      </g>
      <g className={cn(s.pic_rect, 'hero_rect')} filter="url(#j)">
        <rect width="101" height="101" x="225" y="335" fill="url(#k)" rx="8" />
        <path
          fill="#fff"
          filter="url(#l)"
          className="hero_icon"
          id="m"
          d="M269.6 399c-.6 0-1-.2-1.5-.6l-13.4-12.9a2 2 0 0 1 0-3l13.4-12.9c.9-.8 2.3-.8 3 0a2 2 0 0 1 0 3L259.4 384l11.9 11.3a2 2 0 0 1 0 3 2 2 0 0 1-1.6.7Zm12.8 0c-.6 0-1.1-.2-1.6-.6a2 2 0 0 1 0-3l12-11.4-12-11.4a2 2 0 0 1 0-3c.8-.8 2.2-.8 3 0l13.5 13a2 2 0 0 1 0 3l-13.4 12.8c-.4.4-1 .6-1.5.6Z"
        />
      </g>
      <g filter="url(#r)" className={cn(s.pic_red, 'hero_rect-big hero_rect-red')}>
        <rect width="211" height="211" x="225" y="115" fill="url(#s)" rx="16" />
        <g filter="url(#t)" className={cn(s.pic_path, 'hero_path hero_path-red')}>
          <rect width="112" height="112" x="275" y="164" stroke="#fff" rx="6" />
          <path stroke="#fff" d="M273.5 177.5h115" />
          <path
            fill="#fff"
            d="M285 237c0-1.1 1-2 2.2-2h15.6c1.2 0 2.2.9 2.2 2s-1 2-2.2 2h-15.6c-1.2 0-2.2-.9-2.2-2Zm0 12c0-1.1 1-2 2.2-2h20.6c1.2 0 2.2.9 2.2 2s-1 2-2.2 2h-20.6c-1.2 0-2.2-.9-2.2-2Zm0 12c0-1.1 1-2 2.1-2H298c1.1 0 2.1.9 2.1 2s-1 2-2.1 2h-11c-1.1 0-2.1-.9-2.1-2Zm24-24c0-1.1 1-2 2.1-2H325c1.1 0 2.1.9 2.1 2s-1 2-2.1 2h-14c-1.1 0-2.1-.9-2.1-2Zm5 12c0-1.1 1-2 2.2-2h8.6c1.2 0 2.2.9 2.2 2s-1 2-2.2 2h-8.6c-1.2 0-2.2-.9-2.2-2Zm-10 12c0-1.1 1-2 2.2-2h18.6c1.2 0 2.2.9 2.2 2s-1 2-2.2 2H306a2 2 0 0 1-2.1-2Z"
          />
          <path
            fill="#fff"
            d="M314 252.1V246c0-1 .9-1.9 2-1.9s2 .8 2 1.9v6.2c0 1-.9 1.9-2 1.9s-2-.8-2-1.9Zm-18 12V258c0-1 .9-1.9 2-1.9s2 .8 2 1.9v6.2c0 1-.9 1.9-2 1.9s-2-.8-2-1.9Zm5-24V234c0-1 .9-1.9 2-1.9s2 .8 2 1.9v6.2c0 1-.9 1.9-2 1.9s-2-.8-2-1.9Z"
          />
          <path stroke="#fff" d="M326 192.5 312.5 206l13.5 13.5m36 0 13.5-13.5-13.5-13.5" />
          <rect width="4" height="36" x="351.3" y="189.4" fill="#fff" rx="2" transform="rotate(30 351.3 189.4)" />
          <rect width="43" height="34" x="334" y="232" stroke="#fff" rx="6" />
          <rect width="14" height="14" x="287" y="188" stroke="#fff" rx="4" />
          <rect width="14" height="14" x="287" y="210" stroke="#fff" rx="4" />
          <circle cx="377" cy="171" r="2" fill="#fff" />
          <circle cx="369" cy="171" r="2" fill="#fff" />
          <circle cx="361" cy="171" r="2" fill="#fff" />
        </g>
      </g>
      <g filter="url(#u)" className={cn(s.pic_blue, 'hero_rect-big hero_rect-blue')}>
        <rect width="211" height="211" x="445" y="225" fill="url(#v)" rx="16" />
        <g filter="url(#w)" className={cn(s.pic_path, 'hero_path hero_path-blue')}>
          <path
            stroke="#fff"
            d="m551 305-27 41s17 12 17 29m10-70 27 41s-17 12-17 29m-10-70v34m-10 36c-4 0-10 7-10 14h40c0-7-6-14-10-14m-20 0h20m-10-36c4 0 7 3 7 7s-3 7-7 7-7-3-7-7 3-7 7-7Z"
          />
          <path
            fill="#fff"
            d="M507 275h-12v12h12v-12Zm-12-4a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4v-4h21.4a45 45 0 0 0-26.4 39.2 10 10 0 1 0 4 0 41 41 0 0 1 31.5-38 10 10 0 0 0 19 0 41 41 0 0 1 31.5 38 10 10 0 1 0 4 0 45 45 0 0 0-26.4-39.2H591v4a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4v-12a4 4 0 0 0-4-4h-12a4 4 0 0 0-4 4v4h-30.2a10 10 0 0 0-19.6 0H511v-4a4 4 0 0 0-4-4h-12Zm56 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm-43 51a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm92-6a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm-5-57h12v12h-12v-12Z"
          />
        </g>
      </g>
      <defs>
        <linearGradient id="a" x1="660" x2="660" y1="0" y2="660" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0" />
          <stop offset=".2" stopOpacity="0.1" />
          <stop offset=".8" stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="b" x1="550" x2="550" y1="0" y2="660" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0" />
          <stop offset=".2" stopOpacity="0.1" />
          <stop offset=".8" stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="c" x1="330" x2="330" y1="0" y2="660" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0" />
          <stop offset=".2" stopOpacity="0.1" />
          <stop offset=".8" stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="d" x1="770" x2="0" y1="220" y2="220" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.1" />
          <stop offset=".8" stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="e" x1="770" x2="0" y1="110" y2="110" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.1" />
          <stop offset=".8" stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="f" x1="770" x2="0" y1="330" y2="330" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.1" />
          <stop offset=".8" stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="g" x1="770" x2="0" y1="440" y2="440" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.1" />
          <stop offset=".8" stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="h" x1="770" x2="0" y1="550" y2="550" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.1" />
          <stop offset=".8" stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="i" x1="220" x2="220" y1="0" y2="660" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0" />
          <stop offset=".2" stopOpacity="0.1" />
          <stop offset=".8" stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="k" x1="225" x2="326" y1="335" y2="436" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C0C0AA" />
          <stop offset="0" stopColor="#CFCF7B" />
          <stop offset="1" stopColor="#76DAE1" />
        </linearGradient>
        <linearGradient id="p" x1="436" x2="335" y1="445" y2="546" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DBCFFF" />
          <stop offset="1" stopColor="#FAACA8" />
        </linearGradient>
        <linearGradient id="s" x1="436" x2="225" y1="115" y2="326" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF5ACD" />
          <stop offset="1" stopColor="#FBDA61" />
        </linearGradient>
        <linearGradient id="v" x1="445" x2="656" y1="225" y2="436" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9FACE6" />
          <stop offset="1" stopColor="#74EBD5" />
        </linearGradient>
        <filter id="j" width="149" height="149" x="201" y="323" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feMorphology in="SourceAlpha" radius="8" result="effect1_dropShadow" />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="16" />
          <feColorMatrix values="0 0 0 0 0.572549 0 0 0 0 0.843137 0 0 0 0 0.760784 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="l" width="54" height="54" x="249" y="359" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="o" width="149" height="149" x="311" y="433" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feMorphology in="SourceAlpha" radius="8" result="effect1_dropShadow" />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="16" />
          <feColorMatrix values="0 0 0 0 0.945098 0 0 0 0 0.717647 0 0 0 0 0.760784 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="q" width="54" height="54" x="359" y="469" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="r" width="275" height="275" x="193" y="99" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feMorphology in="SourceAlpha" radius="8" result="effect1_dropShadow" />
          <feOffset dy="16" />
          <feGaussianBlur stdDeviation="20" />
          <feColorMatrix values="0 0 0 0 0.992157 0 0 0 0 0.654902 0 0 0 0 0.552941 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="t" width="150" height="150" x="256" y="146" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="u" width="275" height="275" x="413" y="209" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feMorphology in="SourceAlpha" radius="8" result="effect1_dropShadow" />
          <feOffset dy="16" />
          <feGaussianBlur stdDeviation="20" />
          <feColorMatrix values="0 0 0 0 0.509804 0 0 0 0 0.843137 0 0 0 0 0.858824 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="w" width="150" height="150" x="476" y="257" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
