// extracted by mini-css-extract-plugin
export var cases = "_6j_Ch";
export var cases_content = "_6j_Dh";
export var cases_descr = "_6j_Fh";
export var cases_screen = "_6j_Gh";
export var phone = "_6j_Hh";
export var pos_1 = "_6j_Jh";
export var pos_2 = "_6j_Kh";
export var pos_3 = "_6j_Lh";
export var pos_4 = "_6j_Mh";
export var pos_5 = "_6j_Nh";
export var pos_6 = "_6j_Ph";
export var tablet = "_6j_Qh";