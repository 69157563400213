import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '~components/Layout'
import S from '~components/seo'
import { Hero } from './components/Hero'
import { About } from './components/About'
import { Services } from './components/Services'
import { Cases } from '~components/Cases'
import { Cta } from '~components/Cta'

export const Home = () => {
  const data = useStaticQuery(graphql`
    {
      homeJson {
        hero {
          title
          descr
          buttons {
            mode
            text
            href
            icon
          }
        }
        about {
          title
          descr
          list {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 180, quality: 100, placeholder: TRACED_SVG)
            }
            name
          }
        }
        services {
          title
          list {
            title
            descr
            icon
          }
        }
      }
    }
  `)
  const { hero, about, services } = data.homeJson
  return (
    <Layout>
      <S />
      <Hero {...hero} />
      <About {...about} />
      <Services {...services} />
      <Cases />
      <Cta />
    </Layout>
  )
}
